body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --main-white: #f0f0f0;
  --main-red: #be3144;
  --main-blue: #C5480E;
  --main-pink: #D72897;
  --main-gold: #F3D75B;
  --main-green: #C5480E;
}

/* Base reset */
* {
  margin: 0;
  padding: 0;
}

/* box-sizing and font sizing */
*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;

  /* Set font size for easy rem calculations
   * default document font size = 16px, 1rem = 16px, 100% = 16px
   * (100% / 16px) * 10 = 62.5%, 1rem = 10px, 62.5% = 10px
  */
  font-size: 62.5%;
  scroll-behavior: smooth;
  
}



/* Base styles */

body {
  font-family: 'Poppins', sans-serif;
  font-size: 1.8rem; /* 18px */
  font-weight: 400;
  line-height: 1.4;
  color: var(--main-gold);
}

h1,
h2 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  text-align: center;
}

h1 {
  font-size: 6rem;
}

h2 {
  font-size: 4.2rem;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #F3D75B;
}

img {
  display: block;
  width: 100%;
}

/* nav */
.nav {
  background-color: #2e4412 ;
  color:#F3D75B;
  font-size:2rem;
}


#info section {
  height: 270px;
}




a.icon{
  margin:0 auto;

}

body{
  /*font-size: 2em;*/
}
li {
  padding: .5em;
}

#about img{
   margin-top: none;
   margin-top: 75px;
  }
  
.connectButton{
   
    margin-top: none;
    margin-top: 1.5em;
    }



/* Welcome section */

#welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #F9ebcd;
  color: #432616;
  /*background-image: linear-gradient(62deg, #3a3d40 0%, #181719 100%);*/
}
.welcomesection h1{
  font-weight: bold;
}
.welcomesection h2{
  font-weight: bold;
}

.welcomesection  p {
  text-align: center;
  font-size: 3rem;
  font-weight: 300;
  font-style: italic;
  color: #432616;
}

.welcomesection img {
  max-width: 40%;
  max-height: 60%;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.buttonFix{
  font-size: 2rem;
  height: 50px;
  width: 200px;
  align-self: center;
  /*border: none; */
  background: #2e4412;
  border-color: solid #2e4412 2px;
  border-radius: 5px;
  color: #ffffff;
  box-shadow: 2px 4px 3px rgba(0,0,0,0.2);
  padding: 2px;
  text-transform: uppercase;
  margin-bottom: 1em;
}

 /*.buttonFix {
  border: none;
 }*/

 #welcome b{
  color:#ffffff;
  font-size: 2rem;
 }

/* info */
#info{
display: flex;
flex-direction: column;
height: 500px;
background: #ffffff;
justify-content: center;
border-bottom: 5px solid #f78c56;
}

#info section{
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 80%;
  text-align: center;
  color: #432616;
  background-color: #F9ebcd;
  height: 250px;
  box-shadow: 2px 4px 3px rgba(0,0,0,0.2);
  border-radius: 5px;
  border: 3px solid #f78c56;
}

#info p{
  color: #432616;
  font-size: 2.5rem;
}

.sectionTitle{
   text-align:center;
   font-size: 3rem;
   padding-top: 2rem;
   font-weight: bold;
 }

/* services */
#services{
  display: flex;
  flex-direction: column;
  /*height: 105rem;*/
  background-color: #ffffff;
  color:#432616;
}

.serviceintro{
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  height: 100px;
  margin: 0;
  padding-top: 50px;
  padding-bottom: 20px;
}
.service h1{
  font-size: 2.5rem;
  align-self: center;
  padding-bottom: 20px;
  color: #432616;
}
.servicestitle{
  font-weight: bold;
}
.fa-lg{
  margin-top: 2.7rem;
}

.servicesintro p{
  text-align: center;
  align-self: center;
  margin: 0 auto;
  /*padding: 0 15%;*/
  font-size: 3rem;
  color : #432616;
  max-height: 1rem;
  width: 100%;
}
.services{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /*height: 120rem;*/
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 16rem;
}
.services section{
  display: flex;
  flex-direction: column;
  justify-content: first baseline;
  align-items: center;
  width: 70%;
  margin: 11px;
  /*height: 15rem;*/
  padding-top: 2rem;
  padding-left: 5%;
  padding-right: 5%;
  /*font-size: 1.4rem;*/
  color: #432616;
  text-align: center;
  border-radius: 5px;
  background-color: #F9ebcd;
  box-shadow: 2px 4px 3px rgba(0,0,0,0.2);
  border: 3px solid #f78c56;
}

.services h3{
  text-transform: uppercase;
  font-weight: bold;
  font-size: 3rem;
  padding-top: 2rem;
  padding-bottom: 0;
}

.services p{
  padding-top: 3rem;
  padding-bottom: 1rem;
  font-size: 3rem;
}

#services b{
  color:#FFF4F4;
  font-size: 2rem;
}
#services button {
  /*margin-top: 40px;*/
  height: 5rem;
  width: 200px;
  align-self: center;
  border: none;
  background: #2e4412;
  border-color: solid #2e4412 2px;
  border-radius: 5px;
  color: white;
  padding-bottom: 3rem;
  box-shadow: 2px 4px 3px rgba(0,0,0,0.2);
  padding: 2px;
}

/* Projects section */

.projects-sections {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  padding: 4rem 2rem;
  background: #F9ebcd;
  color:#432616
}

.projects-section-header {
  align-items: center;
  /*height: 15rem;*/
  padding-left: 5%;
  padding-right: 5%;
  /*font-size: 1.4rem;*/
  color: #432616;
  text-align: center;
}
 .carousel{
  color:#432616;
 }
 .carousel img{
  height: 30rem;
 }
 .carousel .carousel-caption {
  color: #F3D75B; /* Change to the desired text color */
  position: absolute;
  
  
}

.carousel-header {
  text-align: center;
}

.h2p{
  color:#432616;
  Font-size: 3rem;
  font-weight: bold;
}

.carousel-title {
  font-size: 24px;
  color: #333; /* Change to the desired text color */
}

.carousel-description {
  font-size: 16px;
  color: #666; /* Change to the desired text color */
}

.carousel-container {
  position: relative;
}





@media (max-width: 28.75em) {
  .projects-section-header {
    font-size: 4rem;
  }
}

/* About Section */
#about{
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  height: 800px;
  background: #ffffff;
  align-content: center;
}
#about h1{
  font-size: 3rem;
  padding-top: 100px;
  text-align: center;
  color: #432616;
  padding-top: 30px;
  text-align: center;
}
#about .aboutsoftware{
  font-size: 2.8rem;
  color: #432616;
  padding-bottom: 1em;
}

#about a{
  color:#ffffff;
}
#about p{
  font-size: 2rem;
  width: 100%;
  margin-top: 0;
  /*margin-left: 125px;*/
  text-align: center;
}

#about img{
  display: flex;
  width: 200px;
  height: 200px;
  justify-content: center;
  align-self: center;
  border-radius: 50%;
  justify-content: flex-end;
  box-shadow: 2px 4px 3px rgba(0,0,0,0.2);
}
 .bigabout{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  /*height: 600px;*/
  width: 90%;
  background: #F9ebcd;
  color: #432616;
  align-content: center;
  border-radius: 5px;
  box-shadow: 2px 4px 3px rgba(0,0,0,0.2);
 }

 .aboutfirst{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
 }


 .aboutsecond{
  display: flex;
  width: 100%;
  justify-content: center;
  align-content: center;
  height: 30%;
 } 
 .connectButton{
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 2em;
  }


 .submitbutton{
  font-size: 2rem;
  height: 50px;
  width: 200px;
  align-self: center;
  border: none;
  background: #2e4412;
  /*border-color: solid #2e4412 2px;*/
  border-radius: 5px;
  color: #ffffff;
  box-shadow: 2px 4px 3px rgba(0,0,0,0.2);
  text-transform: uppercase;
  margin: 1rem;
 }


 


 /* Contact Section */
 #contact{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F9ebcd;
  color: #2e4412;
  padding-bottom: 10px;
 }

 #contact h1{
  font-size: 3rem;
  align-self: center;
  font-weight: bold;
 }
 #contact span{
  font-size: 2.5rem;
  align-self: center;
  text-align:center;
 }

 .contactform{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 620px;
  width: 100%;
 }

 .contactform form{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: left;
  width: 60%;
  height: 600px;
  flex-wrap: wrap;
 }

 form input{
  width: 100%;
  height: 50px;
 }
 label {
  display: block;
  margin-bottom: 5px;
 }

 form textarea{
  height: 250px;
  width: 100%;
  align-self: center;
 }

 .buttonfix{
   font-size: 2rem;
   background-color: #2e4412;
 }
 #about button{
  height: 50px;
  width: 200px;
  align-self: center;
  justify-content: center;
  border: none;
  background: #2e4412;
  border-radius: 5px;
  color: #FFF4F4;
  padding: 12px;
  box-shadow: 2px 4px 3px rgba(0,0,0,0.2);
  padding: 2px;
  text-transform: uppercase;
 } 

/* Contact section */

.contact-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 10vh;
  padding: 0 2rem;
  background: #2e4412;
  color: #F3D75B;
}

.contact-section-header > h2 {
  font-size: 6rem;
}

@media (max-width: 28.75em) {
  .contact-section-header > h2 {
    font-size: 4rem;
  }
}

.contact-section-header > p {
  font-style: italic;
  font-size: 3rem;
}

.contact-links {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 980px;
  flex-wrap: wrap;
}

.contact-details {
  font-size: 3rem;
  text-shadow: 2px 2px 1px #1f1f1f;
  transition: transform 0.3s ease-out;
}

.contact-details:hover {
  transform: translateY(8px);
}

/* Footer */


footer {
  font-weight: 300;
  display: flex;
  justify-content: space-evenly;
  padding: 2rem;
  background: #2e4412;
  border-top: 4px solid #f78c56;
  
}

.contact-links{
  font-size: 3rem;
  color:#F3D75B
}

footer  p {
  margin: 2rem;
  font-size: 2.5rem;
  color:#F3D75B;
}

footer i {
  vertical-align: middle;
}

@media (max-width: 28.75em) {
  footer {
    flex-direction: column;
    text-align: center;
  }
}

/* Extra large devices (larger desktops, 1201px and up) */
@media (min-width: 1201px) {
  /* CSS rules for extra large devices */
  #info p{
    color: #432616;
    font-size: 2.5rem;
  }
  .services {
    padding-top: 11rem;
  }
  .servicesintro p{
    margin: 0 auto;
    font-size: 2rem;
    max-height: 1rem;
    width: 100%;
  }
  #services button{
    padding-left: 0px;
    padding-right: 0px;
   }
   .services section{
    width:auto;
   }
   
  
}
/* Large devices (large desktops, 993px to 1200px) */
@media (min-width: 993px) and (max-width: 1200px) {
  /* CSS rules for large devices */
  #info p{
    color: #432616;
    font-size: 2.5rem;
  }
  .servicesintro p{
    margin: 0 auto;
    max-height: 1rem;
    width: 100%;
  }
  .servicestitle{
    font-size: 3rem;
  }
  .carousel .carousel-caption{
    font-size:30rem;
  }
  #services button{
    padding-left: 0px;
    padding-right: 0px;
   }
   .services section{
    width: auto;
  }
  
}
/* Medium devices (landscape tablets and medium desktops, 769px to 992px) */
@media (min-width: 768px) and (max-width: 992px) {
  /* CSS rules for medium devices */
  #info p{
    font-size: 2rem;
  }
  .welcomesection p{
    font-size: 2.5rem;
  }
  .servicesintro p{
    margin: 0 auto;
    font-size: 2.5rem;
    max-height: 1rem;
    width: 100%;
  }
  .services section{
    width: auto;
  }
  .servicestitle{
    font-size: 3rem;
  }
  #about{
    height: 880px;
   }
   #about h1{
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 3rem;
   }
   #about .aboutsoftware{
    font-size: 3rem;
   }
   #about p{
   font-size: 1.7rem;
   }
   .bigabout{
    height: 80%;
   }
   #services button{
    padding-left: 0px;
    padding-right: 0px;
   }
    
  } 
/* Small devices (portrait tablets and small desktops, 577px to 768px) */
@media (min-width: 600px) and (max-width: 767px) {
  /* CSS rules for small devices */
  #info p{
    font-size: 1.7rem;
  }
  .welcomesection p{
    font-size: 2.3rem;
  }
  .servicesintro p{
    margin: 0 auto;
    font-size: 2rem;
    max-height: 1rem;
    width: 100%;
  }
  #services b {
    font-size: 1.4rem;
  }
  .services {
    padding-top: 11rem;
  }
  .carousel-caption{
    font-size:3rem;
  }
 #about{
  height: 880px;
 }
 #about h1{
  margin-top: 0px;
  margin-bottom: 0px;
 }
 #about .aboutsoftware{
  font-size: 2rem;
 }
 #about p{
 font-size: 1.5rem;
 }
 .bigabout{
  height: 80%;
 }
 #services button{
  margin-left: none;
  margin-right: none;
 }
  
}
/* Extra small devices (phones, 576px and down) */
@media (max-width: 599px) {
  /* CSS rules for extra small devices */
  #info p{
    font-size: 1.5rem;
  }
  .welcomesection p{
    font-size: 2.0rem;
  }
  .sectionTitle{
    font-size: 2rem;
  }
  .servicesintro p{
    margin: 0 auto;
    font-size: 2rem;
    max-height: 1rem;
    width: 100%;
  }
  #services b {
    font-size: 1.4rem;
  }
  .carousel-caption{
    font-size:3rem;
  }
 #about{
  height: 880px;
 }
 #about h1{
  margin-top: 0px;
  margin-bottom: 0px;
 }
 #about .aboutsoftware{
  font-size: 2rem;
 }
 #about p{
 font-size: 1.3rem;
 }
 .services p{
  font-size: 1.5rem;
}
.services {
  padding-top: 13rem;
}
 .bigabout{
  height: 80%;
 }
 #services button{
  margin-left: none;
  margin-right: none;
 }
  
} 

@media (max-width: 413px) {
  /* CSS rules for extra extra small devices */
  #info p{
    font-size: 1.3rem;
  }
  .welcomesection p{
    font-size: 1.6rem;
  }
  .sectionTitle{
    font-size: 2rem;
  }
  .servicesintro p{
    margin: 0 auto;
    font-size: 1.7rem;
    max-height: 1rem;
    width: 100%;
  }
  #services b {
    font-size: 1.4rem;
  }
  .services p{
    font-size: 2rem;
  }
  .services {
    padding-top: 13rem;
  }
  .carousel-caption{
    font-size:3rem;
  }
 #about{
  height: 880px;
 }
 #about h1{
  margin-top: 0px;
  margin-bottom: 0px;
 }
 #about .aboutsoftware{
  font-size: 2rem;
 }
 #about p{
 font-size: 1.2rem;
 }
 .bigabout{
  height: 80%;
 }
 #services button{
  margin-left: none;
  margin-right: none;
 }
  
}
@media (max-width: 368px) {
  /* CSS rules for extra extra iphonhe 11 small devices */
  #info p{
    font-size: 1.3rem;
  }
  .sectionTitle{
    font-size: 1.7rem;
  }
  .services h3{
    font-size: 2rem;
  }
  .servicesintro p{
    margin: 0 auto;
    font-size: 1.5rem;
    max-height: 1rem;
    width: 100%;
  }
  #services b {
    font-size: 1.4rem;
  }
  .services section{
    margin: 30px;
  }
  .services p{
    font-size: 1.5rem;
  }
  .carousel-caption{
    font-size:3rem;
  }
 #about{
  height: 95rem;
 }
 #about h1{
  margin-top: 0px;
  margin-bottom: 0px;
 }
 #about .aboutsoftware{
  font-size: 2rem;
 }
 #about p{
 font-size: 1.5rem;
 font-weight: 500;
 }
 .bigabout{
  height: 90%;
 }
 #contact{
  height: auto;
 }
 #contacts h1{
  font-size: 2.5rem;
 }
 #services button{
  margin-left: none;
  margin-right: none;
 }
  
}

@media (max-width: 325px) {
  #about{
    height: 105rem;
   }
   #services button{
    margin-left: none;
    margin-right: none;
   }
   .bigabout {
    height: 95%;
   }
}
@media (max-width: 260px) {
  #about{
    height: 110rem;
   }
   .services h3{
    font-size: 2.0rem;
  }
  .services p{
    font-size: 2.5rem;
  }
   #info p{
    font-size: 1.2rem;
  }
  #services button{
    margin-left: none;
    margin-right: none;
   }
}